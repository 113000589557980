import React, { useEffect } from "react"
import { Layout } from "../../components"
import OgImage from "../../images/position/og-image-linux.admin.png"
import { Helmet } from "react-helmet"
import { Banner, BannerStyle } from "@igloonet-web/shared-ui"
import LinuxAdminKaJobDetail from "./linux-admin-ka-job-detail"

const LinuxAdKa: React.FC = () => {
  useEffect(() => {
    document.body.classList.add("modal-open")

    return function cleanup() {
      document.body.classList.remove("modal-open")
    }
  }, [])
  return (
    <Layout>
      <Helmet>
        <title>Přidej se k nám do igloo! | igloonet</title>
        <meta
          name="description"
          content="Hledáme šikovnou full-time parťačku či
          parťáka do malého týmu v Brně. Někoho, kdo je zodpovědný,
          spolehlivý, s lecčím si poradí a pomůže nám jak s běžnými
          adminovskými starostmi, tak s dalším rozvojem."
        />
        <meta property="og:title" content="Přidej se k nám do igloo!" />
        <meta property="og:image" content={OgImage} />
      </Helmet>

      <Banner
        style={BannerStyle.Jobs}
        orangeHeading="Každý strávíme třetinu života v práci."
        blackHeading="Vybírej chytře, s kým ji prožiješ"
        text="Chceš pracovat v kolektivu, kde bude tvé slovo slyšet?
        Staň se tučňákem a pracuj v igloo. #nejsmezledu"
      />

      <LinuxAdminKaJobDetail />
    </Layout>
  )
}

export default LinuxAdKa
